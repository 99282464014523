const data = [
    {
        image: "chickengalbi.png", 
        title: "Dakgalbi 🌶🌶, 닭  갈비", 
        description: "Pollo salteado con variedad de verduras(repollo, zanahoria, zapallo italiano y cebolla) bañado en una salsa picante especial hecha en casa.",
        price: "CLP 23,000",
    },
    {
        image: "chickenjang.png", 
        title: "Dak jang Jung, 닭 강정 🌶", 
        description: "Alita de pollo , turturo corto fritos con panko y bañado  salsa agridulce.",
        price: "CLP 12,000",
    },
    {
        image: "spicychickensoup.png", 
        title: "Dak Bokum Tang, 닭볶음탕  🌶🌶", 
        description: "Estofado de pollo cocinado con zanahoria y papa junto con una deliciosa salsa en base de basta de aji",
        price: "CLP 23,000",
    },
  ];
  
  export default data;