const data = [
    {
        image: "drinks.png", 
        title: "Gaseosas ❄❄❄", 
        description: "Coco Cola Normal, Coca Light, Sprit y Agua Con Gas.",
        price: "CLP 2,000",
    },
    {
        image: "koreanbebidas.png", 
        title: "Bebidas Coreanas",
        description: "bong bong (봉봉), Coco Palm(코코팜), Bae(배), Sikhye(식혜)",
        price: "CLP 1,800"
    },
  ]
  
  export default data;