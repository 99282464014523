const data = [
    {
        image: "budaezzigue.png", 
        title: "Bu Dae Jigue🌶🌶, 부대 찌개", 
        description: "Sopa picante con variedades de embutidos, verduras, tofu, kimchi y mandu. Plato ideal para 2-3 personas! Se puede pedir con fideos ramen por un cargo adicional.",
        price: "CLP 27,000",
    },

  ]
  
  export default data;