const data = [
    {
        image: "brownie.png", 
        title: "Brownie", 
        description: "",
        price: "CLP 5,900",
    },
    {
        image: "mousse.png", 
        title: "Mousse", 
        description: "",
        price: "CLP 5,900",
    },
    {
        image: "chocovolcan.png", 
        title: "Volcan de Chocolate", 
        description: "",
        price: "CLP 5,900",
    },
    {
        image: "jeolpyun.png", 
        title: "Jeol pyun, 절편", 
        description: "Postre de pastel de arroz frito bañado en miel. Por favor indicar si es alergic@ al mani",
        price: "CLP 4,900",
    },
    {
        image: "bingsu.png", 
        title: "Bingsu, 팥 빙수", 
        description: "Postre tradicional de corea constituido de helado, leche condensada, leche, hielo triturado, frutas de la estacion y porotos rojos dulces. Usualmente para 2-3 personas.",
        price: "CLP 4,900",
    },
  ];
  
  export default data;