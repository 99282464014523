const data = [
    {
        image: "yachaejuk.png", 
        title: "Yache Yuk, 야채 죽 🌱", 
        description: "Gacha de arroz con verduras",
        price: "CLP 7,000",
    },
    {
        image: "jukloco.png", 
        title: "Juk de Loco, 전복 죽", 
        description: "Gacha de arroz con loco",
        price: "CLP 8,000",
    },
  ];
  
  export default data;