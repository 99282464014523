const data = [
    {
        image: "soyu.png", 
        title: "Soyu", 
        description: "Bebida alcoholica en base de arroz. Sabor original, uva, durazno, manzana, arandano y frutilla.",
        price: "CLP 6,000",
    },
    {
        image: "ricewine.png", 
        title: "Makkoli,막걸리", 
        description: "Vino de Arroz",
        price: "CLP 6,000",
    },
  ];
  
  export default data;